import { AcLockObject } from '@utils/ac-lock-object';

export const KEYS = AcLockObject({
	ACCESS_TOKEN: 'access_token',
	ACCOUNT: 'account',
	ACTIVE: 'active',
	ADD: 'add',
	ADMIN: 'admin',
	ANNOUNCEMENTS: 'announcements',
	ARRAY: 'array',
	ARTICLE: 'article',
	ARTICLES: 'articles',
	ASCENDING: 'ascending',
	ATTACHMENTS: 'attachments',
	CART: 'cart',
	CASH: 'cash',
	CHANGE: 'change',
	COLLECTION: 'collection',
	COMPANIES: 'companies',
	COMPANY: 'company',
	COMPANY_ADMIN: 'company_admin',
	COMPANY_ID: 'company_id',
	CONTENTS: 'contents',
	CONVERSATION: 'conversation',
	CONVERSATIONS: 'conversations',
	CREATED_AT: 'created_at',
	CREDIT_CHECK: 'credit_check',
	CURRENT_MONTH: 'current-month',
	CURRENT_PASSWORD: 'current_password',
	CURRENT_QUARTER: 'current-quarter',
	CURRENT_WEEK: 'current-week',
	DATE: 'date',
	DELETE: 'delete',
	DESCENDING: 'descending',
	DRIVERS_LICENSE: 'RB',
	EMAIL: 'email',
	EMPHASIZED: 'emphasized',
	EXPIRES_AT: 'expires_at',
	EXPIRES_IN: 'expires_in',
	FEMALE: 'female',
	FIELDS: 'fields',
	FILES: 'files',
	FILTERS: 'filters',
	FORGOT_PASSWORD: 'forgot-password',
	GROUP: 'group',
	GROUPS: 'groups',
	HOLIDAYS: 'holidays',
	IBAN: 'iban',
	IDENTITY_CARD: 'VN',
	IDENTITY_CHECK: 'identity_check',
	INACTIVE: 'inactive',
	INTERAL_REFERENCE: 'internal_reference',
	LAST_ACTIVITY: 'last_activity',
	LOCALE: 'locale',
	LOCATION: 'location',
	LOCATIONS: 'locations',
	LOCATION_ID: 'location_id',
	LOGIN: 'login',
	MALE: 'male',
	MANAGER: 'manager',
	MEMBER: 'member',
	MESSAGE: 'message',
	MESSAGES: 'messages',
	META: 'meta',
	MODAL: 'modal',
	NAME: 'name',
	NAVIGATION: 'navigation',
	NEW_PASSWORD: 'new_password',
	NEW_PASSWORD_CONFIRMATION: 'new_password_confirmation',
	NEXT: 'next',
	OPTIONS: 'options',
	ORDER: 'order',
	ORDERS: 'orders',
	PAGINATION: 'pagination',
	PASSPORT: 'PP',
	PASSWORD: 'password',
	PASSWORD_CONFIRMATION: 'password_confirmation',
	PENDING: 'pending',
	PERSONAL: 'personal',
	PHONENUMBER: 'phonenumber',
	PREVIOUS: 'previous',
	PREVIOUS_MONTH: 'previous-month',
	PROFILE: 'profile',
	QUERY: 'query',
	REFRESH_TOKEN: 'refresh_token',
	REPORT: 'report',
	REQUEST: 'request',
	RESET_PASSWORD: 'reset-password',
	RESIDENT: 'resident',
	ROLE: 'role',
	SCROLLER: 'ac-scroller',
	SESSION: 'session',
	SUBTLE: 'subtle',
	SUPPORT_EMAIL_ADDRESS: 'contact@acato.nl.nl',
	TITLE: 'title',
	TODAY: 'today',
	TYPE: 'type',
	UNIT: 'unit',
	UPDATE: 'update',
	USER: 'user',
	USERNAME: 'username',
	USERS: 'users',
	USER_NAVIGATION: 'user_navigation',
	USER_ROLE: 'user_role',
	VISIBLE: 'visible',
	CLOSEABLE: 'closeable',
});

import { AcLockObject } from '@utils/ac-lock-object';

export const TITLES = AcLockObject({
  ABOUT: 'Over Gemeente',
  ACCESSIBILITY: 'Toegankelijkheid',
  BASE: 'Gemeente',
  CONTACT: 'Contact',
  COOKIES: 'Cookies',
  FAQ: 'Veelgestelde vragen',
  HOME: 'Overzicht',
  ORGANIZATION: 'Organisatie en werkwijze',
  PRIVACY: 'Privacy',
  PROCLAIMER: 'Proclaimer',
  PUBLICATION: 'Publicatie',
  REACH_OUT: 'Beschikbaarheidsgegevens',
  SEARCH: 'Zoeken',
  THEMES: 'Onderwerpen',
  WEBSITE: 'www.acato.nl',
  WOO: 'WOO verzoek indienen',
});
